import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import Seo from "../components/Seo";

import Layout from "../components/Layout";

import FullWidthImage from "../components/FullWidthImage";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";
import DisplayContentHeader from "../components/DisplayContentHeader";
import BlogList from "../components/BlogList";
import Pagination from "../components/Pagination";
import SocialIcons from "../components/SocialIcons";
import { HTMLContent } from "../components/Content";
const pluralize = require("pluralize");

// eslint-disable-next-line
export const AuthorPostTemplate = ({
  title,
  subtitle,
  footer,
  body,
  summary,
  images,
  options,
  author,
  creationDate,
  posts,
  authors,
  pageContext,
  helmet,
  social,
  previewTitle,
  previewDate,
  previewSummary,
}) => {
  let heroImage, footerImage, smallImage;
  if (images && images.hero) heroImage = getImage(images.hero) || images.hero;
  if (images && images.footer)
    footerImage = getImage(images.footer) || images.footer;
  if (images && images.smallImage) smallImage = images.smallImage;

  const showTitle = options.showTitle;

  if (!author && previewTitle) {
    author = "[Not Set]";
  }

  return (
    <div>
      {helmet || null}

      {heroImage ? (
        <FullWidthImage
          img={heroImage}
          title={author || previewTitle}
          subheading="Author"
          imgPosition="center"
          showTitle={showTitle}
        />
      ) : null}

      <section className="section section--gradient">
        <div className="container">
          <div className="content">
            <div className="content">
              <figure className="image is-inline-block author-icon is-pulled-right pt-0 mt-0 is-hidden-mobile">
                {previewTitle ? (
                  <>
                    <PreviewCompatibleImage
                      imageInfo={{
                        image: smallImage,
                        alt: previewTitle,
                        className: "author-image",
                      }}
                    />
                  </>
                ) : (
                  <>
                    <PreviewCompatibleImage
                      imageInfo={{
                        image: smallImage,
                        alt: author,
                        className: "author-image",
                      }}
                    />
                  </>
                )}
              </figure>

              {previewTitle ? (
                <>
                  <DisplayContentHeader
                    title={previewTitle}
                    parent="Authors"
                    parentSlug="/authors"
                    grandparent="Blog"
                    grandparentSlug="/blog"
                    date={previewDate}
                  />
                </>
              ) : (
                <>
                  <DisplayContentHeader
                    title={author}
                    parent="Authors"
                    parentSlug="/authors"
                    grandparent="Blog"
                    grandparentSlug="/blog"
                    date={creationDate}
                  />
                </>
              )}
            </div>
            {previewSummary ? (
              <>
                <div>{previewSummary}</div>
              </>
            ) : (
              <>
                <div>
                  {body ? (
                    <>
                      <HTMLContent content={body} />
                    </>
                  ) : (
                    <>{summary}</>
                  )}
                </div>
              </>
            )}
            {social ? (
              <>
                <h3 className="is-5 mb-0 pb-0">
                  Follow {previewTitle ? <>{previewTitle}</> : <>{author}</>}:
                </h3>
                <SocialIcons
                  socialIconClass="is-inline social-icon has-text-black pt-0 pl-0"
                  socialClass="pl-0 pt-0"
                  social={social}
                />
              </>
            ) : null}
            {previewTitle ? (
              <>
                <br clear="all" />
              </>
            ) : (
              <>
                <BlogList
                  posts={posts}
                  authors={authors}
                  title={`Blog ${pluralize("Post", posts.length)}:`}
                  emptyMessage="This author has not yet contributed."
                />
                <br clear="all" />
                <Pagination pageContext={pageContext} />
              </>
            )}
          </div>
        </div>
      </section>

      {footerImage ? (
        <FullWidthImage
          img={footerImage}
          subheading={footer}
          imgPosition="center"
          className="footer-image-text"
          showTitle={true}
        />
      ) : null}
    </div>
  );
};

AuthorPostTemplate.propTypes = {
  title: PropTypes.string,
  helmet: PropTypes.object,
  name: PropTypes.string,
  subtitle: PropTypes.string,
  footer: PropTypes.string,
  images: PropTypes.shape({
    hero: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    smallImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    smallImageUrl: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    footer: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  }),
  creationDate: PropTypes.string,
  sortDate: PropTypes.string,
  flags: PropTypes.shape({
    published: PropTypes.bool,
    featured: PropTypes.bool,
  }),
  options: PropTypes.shape({
    published: PropTypes.bool,
    showSummary: PropTypes.bool,
    showTags: PropTypes.bool,
    showComments: PropTypes.bool,
    showAuthorSummary: PropTypes.bool,
    showPrevNext: PropTypes.bool,
    showPopular: PropTypes.bool,
    showRelated: PropTypes.bool,
  }),
  breadcrumbs: PropTypes.shape({
    parent: PropTypes.string,
    parentSlug: PropTypes.string,
    grandparent: PropTypes.string,
    grandparentSlug: PropTypes.string,
  }),
  body: PropTypes.string,
  summary: PropTypes.string,
  author: PropTypes.string,
  social: PropTypes.object,
};

const AuthorPost = ({ data, pageContext }) => {
  let { markdownRemark: post } = data;
  let { allMarkdownRemark: posts } = data;
  let { defaultAuthor } = data;
  let { authors } = data;

  let imageSrc =
    post.frontmatter.images.hero.childImageSharp.gatsbyImageData.images.fallback
      .src;

  authors = authors.nodes;
  posts = posts.edges;

  if (!post.frontmatter.titles) post.frontmatter.titles = {};

  return (
    <Layout>
      <AuthorPostTemplate
        title={post.frontmatter.title}
        subtitle={post.frontmatter.titles.subtitle}
        footer={post.frontmatter.titles.footer}
        images={post.frontmatter.images || defaultAuthor.frontmatter.images}
        options={post.frontmatter.options}
        body={post.html}
        summary={post.frontmatter.summary}
        author={post.frontmatter.name || post.frontmatter.author}
        posts={posts}
        authors={authors}
        pageContext={pageContext}
        helmet={
          <Seo
            title={post.frontmatter.title}
            summary={post.frontmatter.summary}
            image={imageSrc}
            article={false}
          />
        }
        social={post.frontmatter.social}
      />
    </Layout>
  );
};

AuthorPost.propTypes = {
  data: PropTypes.object.isRequired,
};

export default AuthorPost;

export const authorPostQuery = graphql`
  query AuthorPost($id: String!, $name: String!, $skip: Int!, $limit: Int!) {
    markdownRemark(id: { eq: $id }) {
      html
      fields {
        slug
      }
      frontmatter {
        name
        images {
          hero {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                layout: FULL_WIDTH
                placeholder: BLURRED
              )
            }
          }
          smallImage {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                layout: FULL_WIDTH
                placeholder: BLURRED
              )
            }
          }
          smallImageUrl
          footer {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                layout: FULL_WIDTH
                placeholder: BLURRED
              )
            }
          }
        }
        options {
          showTitle
        }
        creationDate
        summary
        social {
          facebook
          twitter
          instagram
          youtube
          google
          linkedin
          github
          reddit
        }
      }
    }
    authors: allMarkdownRemark(
      filter: {
        frontmatter: { advanced: { templateKey: { eq: "author-post" } } }
      }
    ) {
      nodes {
        id
        fields {
          slug
        }
        frontmatter {
          name
        }
      }
    }
    defaultAuthor: markdownRemark(
      frontmatter: { advanced: { templateKey: { eq: "author-page" } } }
    ) {
      html
      frontmatter {
        title
        titles {
          subtitle
          footer
        }
        images {
          hero {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                layout: FULL_WIDTH
                placeholder: BLURRED
              )
            }
          }
          smallImage {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                layout: FULL_WIDTH
                placeholder: BLURRED
              )
            }
          }
          smallImageUrl
        }
        options {
          showTitle
        }
        summary
      }
    }
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___creationDate] }
      filter: {
        frontmatter: {
          advanced: { templateKey: { eq: "blog-post" } }
          author: { eq: $name }
        }
      }
      skip: $skip
      limit: $limit
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            advanced {
              templateKey
            }
            creationDate
            author
            options {
              showTitle
            }
            category
            flags {
              featured
            }
          }
        }
      }
    }
  }
`;
